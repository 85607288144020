/* eslint-disable no-prototype-builtins */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import {
  CLIENTS_OPTIONS,
  PROTOCOLS_OPTIONS,
  INTERVENTION_OPTIONS,
  ORGANIZATION_OPTIONS,
} from 'constants/constants';
import nl from './nl';
import en from './en';
import fr from './fr';

const resources = {
  nl: {
    translation: nl,
  },
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

const detectionOptions = {
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'navigator',
    'htmlTag',
    'path',
    'subdomain',
  ],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    detection: detectionOptions,
  });

export default i18n;

export const interpolate = (param1, param2, language) => {
  let values = '';

  values = CLIENTS_OPTIONS.find((c) => c.value === param2);
  if (!values) values = PROTOCOLS_OPTIONS.find((c) => c.value === param2);
  if (!values) values = INTERVENTION_OPTIONS.find((c) => c.value === param2);
  if (!values) values = ORGANIZATION_OPTIONS.find((c) => c.value === param2);
  if (!values)
    return language ? i18n.t(param1, { lng: language }) : i18n.t(param1);

  const text = language ? i18n.t(param1, { lng: language }) : i18n.t(param1);
  let replaceableString = text.match(/%{.*?}/);
  if (!replaceableString) {
    return text;
  }
  replaceableString = replaceableString.map((b) =>
    b.replace('%{', '').replace('}', '')
  );

  if (values.hasOwnProperty(replaceableString)) {
    const value = values[replaceableString];

    return language
      ? i18n.t(param1, {
          [replaceableString[0]]: i18n.t(value, { lng: language }),
          interpolation: { prefix: '%{', suffix: '}' },
          lng: language,
        })
      : i18n.t(param1, {
          [replaceableString[0]]: i18n.t(value),
          interpolation: { prefix: '%{', suffix: '}' },
        });
  }
  return language ? i18n.t(param1, { lng: language }) : i18n.t(param1);
};
