import { CLINICARD_NAMES } from 'constants/constants';
import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:4a5513e1-3607-4a46-8d69-33e16a41ac9b',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_tFXNfNUiK',
  aws_user_pools_web_client_id: '1dsds33a8qm3b7j719c9ilide',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://ywolw4rjzzgfrikzcq7ny3ty64.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint:
        'https://zizdpvoyd1.execute-api.eu-west-1.amazonaws.com/handpols',
      region: 'eu-west-1',
    },
    {
      name: 'diakhandpolsresultapi',
      endpoint:
        'https://h8eypjpj54.execute-api.eu-west-1.amazonaws.com/handpols',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'diakhandpolsbucket-handpols',
  aws_user_files_s3_bucket_region: 'eu-west-1',
};

const defaultCarepathCards = [
  'vas_painscore',
  'rangeofmotion',
  'mhq_questionnaire',
  'prwhe_questionnaire',
];

export default {
  ...defaultConfigs,
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: true,
  ENABLE_MEDICAL_PROFILE: false,
  ENABLE_MANAGEMENT: true,
  ENABLE_STICKY_CAREPATHITEM: false,
  ENABLE_ACTIVATIONPERIOD_CAREPATHITEM: false,
  INSIGHTS_GRAPH_BLACKLIST: [CLINICARD_NAMES.RANGE_OF_MOTION],
  AWS,
  defaultCarepathCards,
};
