import { CLINICARD_NAMES } from 'global_constants';
import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:ed62c55d-34a3-4d2b-98c6-718f330d4309',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_LKGtCaeMu',
  aws_user_pools_web_client_id: '4jbje4iluj8mi8d9b3erbfqo1j',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://embwyfkqazfqdbshgdabftud6a.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint: 'https://sljmhdcqh4.execute-api.eu-west-1.amazonaws.com/kandf',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'clinicardstorage104326-kandf',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  Auth: {
    cookieStorage: {
      domain:
        process.env.NODE_ENV === 'production'
          ? 'umcg.clinicards.app'
          : 'localhost',
      path: '/',
      expires: 1,
      secure: process.env.NODE_ENV === 'production',
    },
  },
};

const defaultCarepathCards = [
  'bmi',
  'prosthesis_questionnaire',
  'prepare_visit',
  'mood',
  'caregiver_strain_index',
  'vas_painscore',
  'steps',
  'photo',
  'exercises',
  'goals',
];

const medProfileQuestions = ['amputationinfo', 'length'];

export default {
  ...defaultConfigs,
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: true,
  ENABLE_MEDICAL_PROFILE: true,
  ENABLE_MANAGEMENT: true,
  INSIGHTS_GRAPH_WHITELIST: [CLINICARD_NAMES.CSI],
  ONESIGNAL_APP_ID: '97c3572b-0e37-48f0-9e1b-812e96efbb42',
  AWS,
  defaultCarepathCards,
  MEDICAL_PROFILE_QUESTIONS: medProfileQuestions,
};
