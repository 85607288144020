import logo from 'assets/images/logo.svg';
import logoPayoff from 'assets/images/logo_payoff.svg';
import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:6fffdb43-0537-47cb-86c6-60b1e5cc3a4d',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_lLLWGTzOf',
  aws_user_pools_web_client_id: '17e7htk36m75bc3725urft4rfs',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: '8',
    passwordPolicyCharacters: ['REQUIRES_NUMBERS'],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_appsync_graphqlEndpoint:
    'https://zv6h7qj26vdgrdsf4dbefl2h4u.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint:
        'https://zk01ivikq2.execute-api.eu-west-1.amazonaws.com/vitalityb',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'clinicardstorage152925-vitalityb',
  aws_user_files_s3_bucket_region: 'eu-west-1',
};

const defaultCarepathCards = [];

export default {
  ...defaultConfigs,
  APP_TITLE: 'Vitality Butler',
  CONTENT_LANGUAGE: 'nl',
  CONTENT_LANGUAGES: ['nl', 'en'],
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: true,
  ENABLE_CARDS: true,
  ENABLE_MEDICAL_PROFILE: false,
  ENABLE_MANAGEMENT: true,
  ENABLE_CLINICARDS: true,
  ENABLE_ORGANISATION_LEVEL: true,
  ENABLE_ORGANISATION_DETAILS: true,
  ENABLE_INSIGHTS: true,
  ENABLE_ORGANISATION_MULTIPLE: true,
  ENABLE_PERMISSIONS: true,
  ENABLE_STACKED_CAREPATH: true,
  AWS,
  logo,
  logoPayoff,
  defaultCarepathCards,
  DEFAULTCARDS: [
    'zx3421x-77zb-99d2-x2ad-bbb5d3753450',
    'a8a9fa68-613a-4420-87f1-a32496d32384',
    '62369046-980d-4ea6-9e78-1b1b69068e08',
    '682fb663-27b7-4393-9b67-edfef7fa6a36',
    'd32d858a-10fc-4565-81ad-3116052ca9be',
    'cc70d0f5-0712-41fa-a168-d583656afbdf',
    'ab727bcb-83fa-47d2-b2ad-bbb5d3753450',
    'd67803a7-7629-44c3-99c6-a84fe7101fe3',
    '4eb5c4c2-8765-4ebe-9776-5b72712d0725',
    'de673886-5669-4e0d-b142-6fda146d2334',
    'b08e6d29-e87b-42d6-8884-47571c5b93fc',
    '23as12xz-cea7-9923-bf8a-17d8cb552262',
    '8bc940b6-cea7-4445-bf8a-17d8cb552262',
    'ab7672x-93fb-81d2-z2ad-bbb5d3753450',
    '11c0dc5e-76aa-4559-8b1a-b55b0f6d4c7f',
    '8dc61d00-783e-4a9f-8b2c-d9769c68fbf8',
    '37235476-13a1-40d4-8834-eaa3edf15e5d',
  ],
};
