import React from 'react';
import { Auth } from 'aws-amplify';
import { Navigate } from 'react-router-dom';

const Logout = () => {
  window.localStorage.removeItem('organisation');
  Auth.signOut();
  return <Navigate to="/" replace />;
};

export default Logout;
