import logo from 'assets/images/adhday-log.png';
import logoPayoff from 'assets/images/adhday-payoff.png';
import favicon from 'assets/images/adhday_favicon.png';
import favicon16 from 'assets/images/adhday_favicon-16x16.png';
import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:39e2bbec-8a32-4f75-8167-f9860c4060d1',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_6P6h9qoXw',
  aws_user_pools_web_client_id: '1lcmk422le0078s196hcg1nc4t',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://mc3eana5gbettmc2c4dyccxl2a.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-fakeApiId123456',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint: 'https://34vuq8hxd6.execute-api.eu-west-1.amazonaws.com/adhdy',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'clinicardstorage142920-adhdy',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  Auth: {
    cookieStorage: {
      domain:
        process.env.NODE_ENV === 'production'
          ? 'myadhday.clinicards.app'
          : 'localhost',
      path: '/',
      expires: 1,
      secure: process.env.NODE_ENV === 'production',
    },
  },
};

const defaultCarepathCards = [];

export default {
  ...defaultConfigs,
  APP_TITLE: 'MyADHDay',
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: true,
  ENABLE_MEDICAL_PROFILE: false,
  ENABLE_MANAGEMENT: true,
  AWS,
  defaultCarepathCards,
  logo,
  logoPayoff,
  favicon,
  favicon16,
};
