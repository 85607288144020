import React, { Component, useLayoutEffect } from 'react';
import {
  Authenticator,
  Button,
  View,
  useAuthenticator,
  ThemeProvider,
} from '@aws-amplify/ui-react';
import { Helmet } from 'react-helmet';
import { Auth } from 'aws-amplify';
import CONFIGS from 'configs';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import Router from './navigation/Router';
import ErrorBoundary from './ErrorBoundary';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';

class Root extends Component {
  render() {
    return <Router />;
  }
}

const App = ({ t }) => {
  const { logo, APP_TITLE, SSO_ORGANISATIONS } = CONFIGS;

  const buttonColor = '#6099d5';
  const buttonHoverColor = '#7A96BB';

  useLayoutEffect(() => {
    setInterval(() => {
      document
        ?.querySelector('div[data-amplify-router=""]')
        ?.classList.add('amplify-box');
      document
        ?.querySelector('div[data-amplify-theme="default-theme"]')
        ?.classList.add('bg-white');
    }, 10);
  });

  const theme = {
    tokens: {
      colors: {
        background: {
          primary: {
            value: 'white',
          },
        },
        brand: {
          primary: {
            10: '#fff',
            80: buttonColor,
            90: buttonHoverColor,
            100: buttonColor,
          },
        },
      },
    },
  };

  const handleEmailChange = (e) => {
    e.target.value = e.target.value.toLowerCase();
  };

  const ssoOptions =
    SSO_ORGANISATIONS?.map((org) => ({
      value: org.provider,
      label: org.hospital,
    })) || [];

  const formFields = {
    signIn: {
      username: {
        label: t('email'),
        placeholder: t('email'),
        required: true,
        onChange: handleEmailChange,
      },
      password: {
        label: t('password'),
        placeholder: t('password'),
        required: true,
      },
    },
    resetPassword: {
      username: {
        label: t('email'),
        placeholder: t('email'),
        required: true,
        onChange: handleEmailChange,
      },
    },
    confirmResetPassword: {
      password: {
        label: t('password_new'),
        placeholder: '',
        order: 1,
        required: true,
      },
      confirm_password: {
        label: t('password_new_confirm'),
        placeholder: '',
        order: 2,
        required: true,
      },
      confirmation_code: {
        label: t('verification_code'),
        placeholder: '',
        order: 3,
        required: true,
      },
    },
    forceNewPassword: {
      password: {
        label: t('password_new'),
        placeholder: t('password_new'),
        required: true,
      },
      confirm_password: {
        label: t('password_new_confirm'),
        placeholder: t('password_new_confirm'),
        required: true,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: t('code'),
        placeholder: t('code'),
        required: true,
      },
    },
  };

  const components = {
    SignIn: {
      Header() {
        return (
          <div className="text-center mt-3">
            <img src={logo} alt="" width="92" />
            <h1 className="h3 mb-1 mt-3 fw-bold">
              {t('welcome')}
              &nbsp;
              {APP_TITLE}
            </h1>
          </div>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();
        return (
          <div className="text-center m-0">
            <View textAlign="center mb-3">
              <Button
                fontWeight="normal"
                onClick={toResetPassword}
                size="small"
                variation="link"
              >
                {t('forgot_your_password')}
              </Button>
            </View>
            {SSO_ORGANISATIONS?.length > 0 && (
              <View
                className="mt-5"
                style={{ maxWidth: '420px', margin: '0 auto' }}
              >
                <Select
                  inputId="ssoOrganisation"
                  name="ssoOrganisation"
                  options={ssoOptions}
                  isClearable
                  placeholder={t('sso')}
                  className="basic-select"
                  classNamePrefix="select"
                  onChange={(e) =>
                    Auth.federatedSignIn({
                      provider: e.value,
                    })
                  }
                  styles={{
                    control: (base) => ({
                      ...base,
                      textAlign: 'left',
                      minHeight: '45px',
                      border: '1px solid #89949f',
                      borderRadius: '4px',
                    }),
                    option: (base) => ({
                      ...base,
                      textAlign: 'left',
                      padding: '12px',
                    }),
                  }}
                />
              </View>
            )}
            {CONFIGS.ENABLE_OPENID_SSO && SSO_ORGANISATIONS?.length === 0 && (
              <button
                className="btn bluecolor mt-2 mb-2"
                type="button"
                onClick={() => {
                  Auth.federatedSignIn({
                    provider: CONFIGS.SSO_PROVIDER,
                  });
                }}
              >
                <u>{t('login_surfconext')}</u>
              </button>
            )}
            {CONFIGS.ENABLE_AZURE_SSO && SSO_ORGANISATIONS?.length === 0 && (
              <button
                className="btn bluecolor mt-2 mb-2"
                type="button"
                onClick={() => {
                  Auth.federatedSignIn({
                    provider: CONFIGS.SSO_PROVIDER,
                    customstate: new Date().getTime().toString(),
                  });
                }}
              >
                <u>{t('login_ad')}</u>
              </button>
            )}
          </div>
        );
      },
    },
    ResetPassword: {
      Header() {
        return (
          <div className="text-center mt-3">
            <img src={logo} alt="" width="92" />
            <h1 className="h3 m-1 fw-bold mt-3">{t('oops_lost_something')}</h1>
            <small>{t('forgot_password_explain')}</small>
          </div>
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        return (
          <div className="text-center mt-3">
            <img src={logo} alt="" width="92" />
            <h1 className="h3 m-1 fw-bold mt-3">{t('enter_new_password')}</h1>
            <small>{t('enter_new_password_explain')}</small>
          </div>
        );
      },
    },
    ForceNewPassword: {
      Header() {
        return (
          <div className="text-center mt-3">
            <img src={logo} alt="" width="92" />
            <h1 className="h3 mb-3 fw-bold ">{t('confirm_account')}</h1>
            <p>
              <small>
                {t('confirm_account_explain', {
                  app_name: CONFIGS.APP_TITLE || 'Clinicard',
                })}
              </small>
            </p>
          </div>
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        return (
          <div className="text-center mt-3">
            <img src={logo} alt="" width="92" />
            <h1 className="h3 mb-3 fw-bold">
              {t('welcome')}
              &nbsp;
              {APP_TITLE}
            </h1>
          </div>
        );
      },
    },
  };

  return (
    <ErrorBoundary>
      <Helmet
        titleTemplate={`${CONFIGS.APP_TITLE} - %s`}
        defaultTitle={CONFIGS.APP_TITLE}
      >
        <meta name="description" content={CONFIGS.APP_TITLE} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Authenticator
          components={{
            ...components,
          }}
          formFields={formFields}
          hideSignUp
        >
          {() => <Root />}
        </Authenticator>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

App.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(App);
