import CONFIGS from 'configs';
import nl from './nl.json';
import ikherstel from './ikherstel.json';
import pet from './pet.json';

const clientSpecificTranslations =
  {
    // CLIENT SPECIFIC TRANSLATIONS
    ikherstel,
    pet,
  }[CONFIGS.ENV] || {};

export default { ...nl, ...clientSpecificTranslations };
