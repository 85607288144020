import logo from 'assets/images/zorgpunt_logo.png';
import logoPayoff from 'assets/images/zorgpunt_payoff.svg';
import favicon from 'assets/images/zorgpunt_favicon.png';
import favicon16 from 'assets/images/zorgpunt_favicon-16x16.png';

import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:f11d3ccf-a542-4a0a-82de-98f1fb6133e0',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_4KmBgy5ID',
  aws_user_pools_web_client_id: '6a667b05eodr5vpe664q30njhu',
  oauth: {
    domain: 'zorgpunt.auth.eu-west-1.amazoncognito.com',
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'https://www.zorgpunt.clinicards.app/',
    redirectSignOut: 'https://zorgpunt.clinicards.app/s',
    responseType: 'code',
  },
  aws_appsync_graphqlEndpoint:
    'https://5sn4pwotubhs7nt7e4x2q2or4m.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint: 'https://9htuwcpor3.execute-api.eu-west-1.amazonaws.com/zpunt',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'clinicardstorage100600-zpunt',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  Auth: {
    cookieStorage: {
      domain:
        process.env.NODE_ENV === 'production'
          ? 'zorgpunt.clinicards.app'
          : 'localhost',
      path: '/',
      expires: 1,
      secure: process.env.NODE_ENV === 'production',
    },
  },
};

const defaultCarepathCards = [];

export default {
  ...defaultConfigs,
  APP_TITLE: 'ZorgPunt',
  APP_CODE: 'CLIN-APP-0022',
  CONTENT_LANGUAGE: 'nl',
  CONTENT_LANGUAGES: ['nl'],
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: false,
  ENABLE_CARDS: true,
  ENABLE_MEDICAL_PROFILE: false,
  ENABLE_MANAGEMENT: true,
  ENABLE_CLINICARDS: true,
  ENABLE_ORGANISATION_LEVEL: true,
  ENABLE_ORGANISATION_DETAILS: true,
  ENABLE_INSIGHTS: false,
  ENABLE_ORGANISATION_MULTIPLE: true,
  ENABLE_PERMISSIONS: true,
  ENABLE_CHILD_PROFILE: true,
  ENABLE_PATIENT_TRANSFER: false,
  ENABLE_LIST_CHILD_NAME: true,
  AWS,
  logo,
  logoPayoff,
  defaultCarepathCards,
  favicon,
  favicon16,
  DEFAULTCARDS: [
    '62369046-980d-4ea6-9e78-1b1b69068e08',
    '7c91f545-4075-40a1-8f27-1e393b06bca4',
    '54wsa12g-cea7-9923-bf8a-17d8cb552262',
    'de673886-5669-4e0d-b142-6fda146d2334',
    '54ds123sa-cea7-9923-12as-17d8cb552262',
  ],
  ENABLE_PATIENT_MFA: true,
  ENABLE_OPENID_SSO: false,
  ENABLE_AZURE_SSO: true,
  SSO_PROVIDER: 'ZorgpuntAzureAD',
  ENABLE_VPK: true,
  ENABLE_PERSONAL_MESSAGES: true,
  ENABLE_HIDE_PATIENT_BIRTHDATE: true,
  REMOVE_GENDER_FROM_ONBOARDING: true,
  ENABLE_BIRTH_INFO: false,
  VISUALISE_INACTIVE_USERS: true,
};
