export const GRAPHQL_LIMIT = 9999;

export const CLINICARD_NAMES = {
  LIFE_CONTROL: 'Mate van zelfregie',
  STEPS: 'steps',
  PROSTHESIS: 'prosthesis_questionnaire',
  VAS: 'vas_painscore',
  VAS_CHESTPAIN: 'vas_chestpain',
  VAS_SHORTBREATH: 'vas_shortbreath',
  MOOD: 'mood',
  PHOTO: 'photo',
  NOTE: 'note',
  BMI: 'bmi',
  EXERCISE: 'exercises',
  CSI: 'caregiver_strain_index',
  PREPARE_VISIT: 'prepare_visit',
  GOALS: 'goals',
  SATURATION: 'saturation',
  HEARTRATE: 'heartrate',
  MOTHER_MILK: 'mother_milk_hc_name',
};

export const SUPPORTED_DATE_FORMATS = [
  'D-M-YY',
  'D-M-YYYY',
  'D-MM-YY',
  'D-MM-YYYY',
  'DD-M-YY',
  'DD-M-YYYY',
  'DD-MM-YY',
  'DD-MM-YYYY',
];

export const PETCARDS_ID_TYPES = [
  {
    value: 'chip',
    translations: {
      en: 'Chip',
      nl: 'Chip',
    },
  },
  {
    value: 'tattoo',
    translations: {
      en: 'Tattoo',
      nl: 'Tatoeage',
    },
  },
  {
    value: 'pedigree',
    translations: {
      en: 'Pedigree',
      nl: 'Stamboom of stamboeknummer',
    },
  },
  {
    value: 'passport',
    translations: {
      en: 'Passport',
      nl: 'Paspoort',
    },
  },
  {
    value: 'tag',
    translations: {
      en: 'Tag',
      nl: 'Tag',
    },
  },
];

export const CAREINSTITUTION_CATEGORIES = [
  { value: 'vsv', text: 'vsv' },
  { value: 'hospital', text: 'hospital' },
  { value: '1st line', text: '1st_line' },
  { value: 'echo center', text: 'echo_center' },
  { value: 'maternity care', text: 'maternity_care' },
  { value: 'lactation expert', text: 'lactation_expert' },
];

export const CHILD_STATUS = [
  { value: 'admitted', text: 'admitted' },
  { value: 'discharged', text: 'discharged' },
  { value: 'deceased', text: 'deceased' },
];

export const CLIENTS_OPTIONS_DROPDOWN = [
  { value: 'patients', text: 'patients' },
  { value: 'clients', text: 'clients' },
  { value: 'pregnant_women', text: 'pregnant_women' },
  { value: 'subjects', text: 'subjects' },
  { value: 'respondents', text: 'respondents' },
  { value: 'users', text: 'users' },
  { value: 'parents', text: 'parents' },
];

export const PROTOCOLS_OPTIONS_DROPDOWN = [
  { value: 'carepaths', text: 'carepaths' },
  { value: 'protocols', text: 'protocols' },
];

export const INTERVENTION_OPTIONS_DROPDOWN = [
  { value: 'intervention_date', text: 'intervention_date' },
  { value: 'due_date', text: 'due_date' },
  { value: 'delivery_date', text: 'delivery_date' },
  { value: 'start_date', text: 'start_date' },
  { value: 'surgery_date', text: 'surgery_date' },
  { value: 'reference_date', text: 'reference_date' },
];

export const ORGANIZATIONS_OPTIONS_DROPDOWN = [
  { value: 'organizations', text: 'organizations' },
  { value: 'hospitals', text: 'hospitals' },
  { value: 'departments', text: 'departments' },
  { value: 'specialties', text: 'specialties' },
  { value: 'location', text: 'locations' },
];

export const CLIENTS_OPTIONS = [
  {
    value: 'users',
    subject: 'user',
    subjects: 'users',
    subject_capital: 'user_capital',
    subjects_capital: 'users_capital',
    the_subject: 'the_user',
    the_subjects: 'the_users',
    this_subject: 'this_user',
    this_subjects: 'this_users',
  },
  {
    value: 'clients',
    subject: 'client',
    subjects: 'clients',
    subject_capital: 'client_capital',
    subjects_capital: 'clients_capital',
    the_subject: 'the_client',
    the_subjects: 'the_clients',
    this_subject: 'this_client',
    this_subjects: 'this_clients',
  },
  {
    value: 'pregnant_women',
    subject: 'pregnant_woman',
    subjects: 'pregnant_women',
    subject_capital: 'pregnant_woman_capital',
    subjects_capital: 'pregnant_women_capital',
    the_subject: 'the_pregnant_woman',
    the_subjects: 'the_pregnant_women',
    this_subject: 'this_pregnant_woman',
    this_subjects: 'this_pregnant_women',
  },
  {
    value: 'subjects',
    subject: 'subject',
    subjects: 'subjects',
    subject_capital: 'subject_capital',
    subjects_capital: 'subjects_capital',
    the_subject: 'the_subject',
    the_subjects: 'the_subjects',
    this_subject: 'this_subject',
    this_subjects: 'this_subjects',
  },
  {
    value: 'respondents',
    subject: 'respondent',
    subjects: 'respondents',
    subject_capital: 'respondent_capital',
    subjects_capital: 'respondents_capital',
    the_subject: 'the_respondent',
    the_subjects: 'the_respondents',
    this_subject: 'this_respondent',
    this_subjects: 'this_respondents',
  },
  {
    value: 'patients',
    subject: 'patient',
    subjects: 'patients',
    subject_capital: 'patient_capital',
    subjects_capital: 'patients_capital',
    the_subject: 'the_patient',
    the_subjects: 'the_patients',
    this_subject: 'this_patient',
    this_subjects: 'this_patients',
  },
  {
    value: 'parents',
    subject: 'parent',
    subjects: 'parents',
    subject_capital: 'parent_capital',
    subjects_capital: 'parents_capital',
    the_subject: 'the_parent',
    the_subjects: 'the_parents',
    this_subject: 'this_parent',
    this_subjects: 'this_parents',
  },
];

export const PROTOCOLS_OPTIONS = [
  {
    value: 'carepaths',
    protocol: 'carepath',
    protocols: 'carepaths',
    protocol_capital: 'carepath_capital',
    protocols_capital: 'carepaths_capital',
  },
  {
    value: 'protocols',
    protocol: 'protocol',
    protocols: 'protocols',
    protocol_capital: 'protocol_capital',
    protocols_capital: 'protocols_capital',
  },
];

export const INTERVENTION_OPTIONS = [
  {
    value: 'intervention_date',
    int_date: 'intervention_date',
    int_date_capital: 'intervention_date_capital',
    intervention: 'intervention',
  },
  {
    value: 'due_date',
    int_date: 'due_date',
    int_date_capital: 'due_date_capital',
    intervention: 'due_date',
  },
  {
    value: 'delivery_date',
    int_date: 'delivery_date',
    int_date_capital: 'delivery_date_capital',
    intervention: 'delivery',
  },
  {
    value: 'start_date',
    int_date: 'start_date',
    int_date_capital: 'start_date_capital',
    intervention: 'start_date',
  },
  {
    value: 'surgery_date',
    int_date: 'surgery_date',
    int_date_capital: 'surgery_date_capital',
    intervention: 'surgery_int',
  },
  {
    value: 'reference_date',
    int_date_capital: 'reference_date',
    reference_date_capital: 'reference_date_capital',
    intervention: 'reference_date',
  },
];

export const ORGANIZATION_OPTIONS = [
  {
    value: 'specialties',
    organization: 'specialty',
    organizations: 'specialties',
    organization_capital: 'specialty_capital',
    organizations_capital: 'specialties_capital',
    the_organization: 'the_specialty',
    the_organizations: 'the_specialties',
    this_organization: 'this_specialty',
    this_organizations: 'this_specialties',
  },
  {
    value: 'organizations',
    organization: 'organization',
    organizations: 'organizations',
    organization_capital: 'organization_capital',
    organizations_capital: 'organizations_capital',
    the_organization: 'the_organization',
    the_organizations: 'the_organizations',
    this_organization: 'this_organization',
    this_organizations: 'this_organizations',
  },
  {
    value: 'locations',
    organization: 'location',
    organizations: 'locations',
    organization_capital: 'location_capital',
    organizations_capital: 'locations_capital',
    the_organization: 'the_location',
    the_organizations: 'the_locations',
    this_organization: 'this_location',
    this_organizations: 'this_locations',
  },
  {
    value: 'hospitals',
    organization: 'hospital',
    organizations: 'hospitals',
    organization_capital: 'hospital_capital',
    organizations_capital: 'hospitals_capital',
    the_organization: 'the_hospital',
    the_organizations: 'the_hospitals',
    this_organization: 'this_hospital',
    this_organizations: 'this_hospitals',
  },
  {
    value: 'departments',
    organization: 'department',
    organizations: 'departments',
    organization_capital: 'department_capital',
    organizations_capital: 'departments_capital',
    the_organization: 'the_department',
    the_organizations: 'the_departments',
    this_organization: 'this_department',
    this_organizations: 'this_departments',
  },
];
