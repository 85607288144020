import React, { Suspense, lazy } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Logout from 'features/auth/components/Logout';
import PrivateRoute from './PrivateRoute';
import { LOGOUT } from './PageUrls';

const Main = lazy(() => import('./InnerRouter'));

const Router = () => (
  <BrowserRouter>
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path={LOGOUT} element={<Logout />} />
        <Route
          path="/*"
          element={<PrivateRoute path="/*" component={Main} />}
        />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default Router;
