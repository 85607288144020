import logo from 'assets/images/mijn_hand_logo.png';
import logoPayoff from 'assets/images/mijnhand_payoff.svg';
import favicon from 'assets/images/mijnHand_favicon.png';
import favicon16 from 'assets/images/mijnHand_favicon-16x16.png';

import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_appsync_graphqlEndpoint:
    'https://4bjn63hyuvbghb34xlyzrw3si4.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint:
        'https://xbd8ogv2bl.execute-api.eu-west-1.amazonaws.com/mijnhand',
      region: 'eu-west-1',
    },
  ],
  aws_cognito_identity_pool_id:
    'eu-west-1:0f9f2a5b-3051-4986-aed5-0378d06701c8',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_UguWQVdof',
  aws_user_pools_web_client_id: '2kf6gmd5t98njnrppmv2206e01',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: '8',
    passwordPolicyCharacters: ['REQUIRES_NUMBERS'],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket: 'clinicardstorage135735-mijnhand',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  aws_public_images_bucket: 'publicarticleimages135735-mijnhand',
  aws_public_images_bucket_region: 'eu-west-1',
};

const defaultCarepathCards = [];

export default {
  ...defaultConfigs,
  APP_TITLE: 'MijnHand',
  CONTENT_LANGUAGE: 'nl',
  CONTENT_LANGUAGES: ['nl', 'en'],
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: true,
  ENABLE_CARDS: true,
  ENABLE_MEDICAL_PROFILE: false,
  ENABLE_MANAGEMENT: true,
  ENABLE_CLINICARDS: true,
  ENABLE_ORGANISATION_LEVEL: true,
  ENABLE_ORGANISATION_DETAILS: true,
  ENABLE_INSIGHTS: true,
  ENABLE_ORGANISATION_MULTIPLE: true,
  ENABLE_PERMISSIONS: true,
  ENABLE_STACKED_CAREPATH: true,
  AWS,
  defaultCarepathCards,
  logo,
  logoPayoff,
  favicon,
  favicon16,
  DEFAULTCARDS: [],
  ENABLE_ANALYSE: true,
  ENABLE_SIGNALING: true,
  ENABLE_REFERENCE: true,
};
