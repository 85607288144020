/* eslint-disable no-console */
import 'react-app-polyfill/ie11';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSpinner,
  faTrashAlt,
  faMinusSquare,
} from '@fortawesome/free-solid-svg-icons';
import { Amplify } from 'aws-amplify';
import CONFIGS from './configs';
import './styles/clinicards.scss';
import './assets/locale/i18n';
import App from './App';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://5d14953f37e449d9ba1028967fdea258@o227106.ingest.sentry.io/1380987',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}

const { AWS } = CONFIGS;
Amplify.configure({
  ...AWS,
});

const { favicon, favicon16 } = CONFIGS;
library.add([faSpinner, faTrashAlt, faMinusSquare]);
let link = document.querySelector("link[rel~='icon']");
if (!link) {
  link = document.createElement('link');
  link.rel = 'icon';
  document.head.appendChild(link);
}
link.href = favicon;
link.type = 'image/x-icon';
document.head.appendChild(link);
const otherFaviconFormats = [
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: favicon16,
  },
];

otherFaviconFormats.forEach((format) => {
  const otherLink = document.createElement('link');
  otherLink.rel = format.rel;
  otherLink.type = format.type;
  otherLink.sizes = format.sizes;
  otherLink.href = format.href;
  document.head.appendChild(otherLink);
});
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
