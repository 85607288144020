import logo from 'assets/images/nazorg_logo.png';
import logoPayoff from 'assets/images/nazorg_payoff.png';
import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:ad1e3983-c9b4-4de0-a644-d21e8a6e960a',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_jAmSmfv4j',
  aws_user_pools_web_client_id: '71k85k93ofld2lcamfjtd0dis3',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: '8',
    passwordPolicyCharacters: ['REQUIRES_NUMBERS'],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_appsync_graphqlEndpoint:
    'https://ha6dclrmwjdctda3xroh5g5zke.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint:
        'https://3tpfn6p7e2.execute-api.eu-west-1.amazonaws.com/aftercare',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'clinicardstorage100823-aftercare',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  aws_cognito_login_mechanisms: ['EMAIL'],
  Auth: {
    cookieStorage: {
      domain:
        process.env.NODE_ENV === 'production'
          ? 'nazorg.clinicards.app'
          : 'localhost',
      path: '/',
      expires: 1,
      secure: process.env.NODE_ENV === 'production',
    },
  },
};

const defaultCarepathCards = [];

export default {
  ...defaultConfigs,
  APP_TITLE: 'Nazorg',
  APP_CODE: 'CLIN-APP-0018',
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: true,
  ENABLE_MEDICAL_PROFILE: false,
  ENABLE_MANAGEMENT: true,
  ENABLE_ORGANISATION_LEVEL: true,
  ENABLE_OPERATION_PROFILE: true,
  ENABLE_PERMISSIONS: true,
  ENABLE_ORGANISATION_MULTIPLE: true,
  ENABLE_CARDS: true,
  ENABLE_STACKED_CAREPATH: true,
  AWS,
  defaultCarepathCards,
  logo,
  logoPayoff,
};
