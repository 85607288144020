import logo from 'assets/images/jauni_icon.png';
import logoPayoff from 'assets/images/jauni_icon_payoff.png';
import favicon from 'assets/images/jauni_favicon.png';
import favicon16 from 'assets/images/jauni_favicon-16x16.png';
import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:c937d886-9ccf-4df9-a4dd-fd639a0cccf9',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_c6BDJuwBk',
  aws_user_pools_web_client_id: '2fi7l4goi015sef5ekfblcdv6q',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: '8',
    passwordPolicyCharacters: ['REQUIRES_NUMBERS'],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_appsync_graphqlEndpoint:
    'https://4cvqa47qijdfdobgwnv5eqgcp4.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint:
        'https://js24jqdnc4.execute-api.eu-west-1.amazonaws.com/jaundice',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'clinicardstorage164947-jaundice',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  aws_public_images_bucket: 'publicarticleimages164947-jaundice',
  aws_public_images_bucket_region: 'eu-west-1',
};

const defaultCarepathCards = [];

export default {
  ...defaultConfigs,
  APP_TITLE: 'Jauni',
  CONTENT_LANGUAGE: 'nl',
  CONTENT_LANGUAGES: ['nl', 'en'],
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: true,
  ENABLE_CARDS: true,
  ENABLE_MEDICAL_PROFILE: false,
  ENABLE_MANAGEMENT: true,
  ENABLE_CLINICARDS: true,
  ENABLE_ORGANISATION_LEVEL: true,
  ENABLE_ORGANISATION_DETAILS: true,
  ENABLE_INSIGHTS: true,
  ENABLE_ORGANISATION_MULTIPLE: true,
  ENABLE_PERMISSIONS: true,
  ENABLE_CHILD_PROFILE: true,
  ENABLE_STACKED_CAREPATH: true,
  ENABLE_LIST_CHILD_NAME: true,
  REMOVE_GENDER_FROM_ONBOARDING: true,
  ENABLE_HIDE_PATIENT_BIRTHDATE: true,
  AWS,
  logo,
  logoPayoff,
  defaultCarepathCards,
  favicon,
  favicon16,
  DEFAULTCARDS: [
    'a8a9fa68-613a-4420-87f1-a32496d32384',
    '8b0bfb3f-4c14-417c-b86e-e9ca96f14a66',
    '54wsa12g-cea7-9923-bf8a-17d8cb552262',
    '72d83d8f-bc4e-470c-8150-dfe5de6fe303',
    '8dc61d00-783e-4a9f-8b2c-d9769c68fbf8',
    'bee6fda1-8f96-416e-ae72-ada59daa61be',
  ],
  CHOOSE_CAREPATH: false,
  VISUALISE_INACTIVE_USERS: true,
};
