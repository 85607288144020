import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { LOGIN } from './PageUrls';

const PrivateRoute = (props) => {
  const [authState, setAuthState] = useState({
    loaded: false,
    isAuthenticated: false,
  });
  const navigate = useNavigate();
  const location = useLocation();

  const authenticate = useCallback(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const groups = user.signInUserSession.idToken.payload['cognito:groups'];
        if (groups && groups.length > 0) {
          setAuthState({
            loaded: true,
            isAuthenticated: true,
          });
        } else {
          // eslint-disable-next-line no-console
          console.error("Current user isn't part of a group.");
          Auth.signOut();
          navigate(LOGIN);
        }
      })
      .catch(() => navigate(LOGIN));
  }, [navigate]);

  useEffect(() => {
    authenticate();
  }, [authenticate]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => null)
      .catch(() => {
        if (authState.isAuthenticated)
          setAuthState({ isAuthenticated: false, loaded: authState.loaded });
      });
  }, [location, authState.isAuthenticated, authState.loaded]);

  const { component: Component } = props;
  if (!authState.loaded) return null;
  return authState.isAuthenticated ? (
    <Component />
  ) : (
    <Navigate
      to={{
        pathname: LOGIN,
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PrivateRoute;
