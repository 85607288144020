import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:6c8d799f-35e1-4e9d-aec1-7275ff8adc72',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_BlmZ7SIk9',
  aws_user_pools_web_client_id: '26qgkek0ep8cg4h7i5p9oabfoa',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://vpn4imvns5am5dseailmerdkza.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint: 'https://rqlx5fl9nk.execute-api.eu-west-1.amazonaws.com/oca',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'clinicardstorage92603-oca',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  Auth: {
    cookieStorage: {
      domain:
        process.env.NODE_ENV === 'production'
          ? 'olvg.clinicards.app'
          : 'localhost',
      path: '/',
      expires: 1,
      secure: process.env.NODE_ENV === 'production',
    },
  },
};

const defaultCarepathCards = [
  'temperature',
  'blood_pressure',
  'heartrate',
  'saturation',
  'vas_painscore',
  'treatment_questionnaire',
  'information_questionnaire',
  'eating_questionnaire',
  'bmi',
  'exercises_questionnaire',
];

export default {
  ...defaultConfigs,
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: true,
  ENABLE_MANAGEMENT: true,
  ENABLE_MEDICAL_PROFILE: false,
  ENABLE_VIDEOCALLING: true,
  AWS,
  defaultCarepathCards,
};
