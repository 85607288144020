import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:0349c45a-edc3-43bb-8632-5f6a8c99152b',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_eXf0d4J3l',
  aws_user_pools_web_client_id: '66qs0nuqaklctf9f78do2q3t9n',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://jo2oorfsqvexrmuwnr5tpg3lrq.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-fakeApiId123456',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint: 'https://gyx7mjt6u1.execute-api.eu-west-1.amazonaws.com/lungpe',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'clinicardstorage111843-lungpe',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  Auth: {
    cookieStorage: {
      domain:
        process.env.NODE_ENV === 'production'
          ? 'erasmus-longembolie.clinicards.app'
          : 'localhost',
      path: '/',
      expires: 1,
      secure: process.env.NODE_ENV === 'production',
    },
  },
};

const defaultCarepathCards = [
  'vas_shortbreath',
  'saturation',
  'steps',
  'vas_chestpain',
  'heartrate',
];

export default {
  ...defaultConfigs,
  ENABLE_ARTICLES: true,
  ENABLE_MANAGEMENT: true,
  AWS,
  defaultCarepathCards,
};
