import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:b4e517cd-fcf2-4d89-a1ac-4e51f8f755cb',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_DAsTykbPn',
  aws_user_pools_web_client_id: 's976q2b3luu3m0d527k5re4f2',
  aws_appsync_graphqlEndpoint:
    'https://32dup4mmffcu5nwjdjnkitbhlq.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'slingelandapi',
      endpoint:
        'https://xbx5la15nc.execute-api.eu-west-1.amazonaws.com/slingeland',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'slingelandbucket-slingeland',
  aws_user_files_s3_bucket_region: 'eu-west-1',
};

const defaultCarepathCards = ['Mate van zelfregie'];

export default {
  ...defaultConfigs,
  ENABLE_ARTICLES: true,
  AWS,
  defaultCarepathCards,
};
