import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import { withTranslation, Trans } from 'react-i18next';
import CONFIGS from 'configs';

import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    Sentry.captureException(error);
    return {
      hasError: true,
    };
  }

  render() {
    const { t, children } = this.props;
    const { hasError } = this.state;
    const reloadPage = () => {
      window.location.reload(true);
    };
    const { logoPayoff } = CONFIGS;
    if (hasError) {
      return (
        <div className="d-flex align-items-center flex-column justify-content-center h-100">
          <img
            src={logoPayoff}
            alt={`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`}
            width="500"
            style={{ objectFit: 'contain' }}
          />
          <h1 className="display-5 mt-5 mb-5">{t('error_occured')}</h1>
          <p className="text-center">
            <Trans
              i18nKey="error_text"
              values={{ service_desk_email: CONFIGS.SERVICE_DESK_EMAIL }}
            >
              This error has been passed on to our internal system but you can
              also forward the error with extra information to&nbsp;
              <a href={`mailto: ${CONFIGS.SERVICE_DESK_EMAIL}`}>
                {CONFIGS.SERVICE_DESK_EMAIL}
              </a>
              <br />
              <br />
              You can refresh the page in its entirety by
              <Button onClick={reloadPage} className="ps-0 pe-1" color="link">
                pressing here
              </Button>
              It is also advisable to empty your temporary internet files
              (browser cache) to prevent errors
            </Trans>
          </p>
        </div>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default withTranslation()(ErrorBoundary);
