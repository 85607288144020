import { CLINICARD_NAMES } from 'constants/constants';
import logo from 'assets/images/logo.svg';
import logoPayoff from 'assets/images/logo_payoff.svg';

export default {
  APP_TITLE: 'Clinicards',
  ENABLE_CE: false,
  SITE_LANGUAGES: ['nl', 'en'],
  ENABLE_STACKED_CAREPATH: false,
  ENABLE_CARETEAM_SHARED: true,
  ENABLE_CLINICARDS: true,
  ENABLE_ARTICLES: false,
  ENABLE_CARDS: false,
  ENABLE_CAREPATH: false,
  ENABLE_PERSONAL_MESSAGES: false,
  ENABLE_MEDICAL_PROFILE: false,
  ENABLE_OPERATION_PROFILE: false,
  ENABLE_MANAGEMENT: false,
  ENABLE_PERMISSIONS: false,
  ENABLE_INCLUSION: false,
  ENABLE_ORGANISATION_LEVEL: false,
  ENABLE_ORGANISATION_DETAILS: false,
  ENABLE_ORGANISATION_MULTIPLE: false,
  ENABLE_CHILD_PROFILE: false,
  ENABLE_INSIGHTS: true,
  ENABLE_STICKY_CAREPATHITEM: false,
  ENABLE_CAREPATHS_ARTICLES: false,
  ENABLE_ACTIVATIONPERIOD_CAREPATHITEM: false,
  ENABLE_RANDOMISATION: false,
  ENABLE_INSTANCE_SPECIC_NAMING: false,
  ENABLE_HIDE_PATIENT_BIRTHDATE: false,
  REMOVE_GENDER_FROM_ONBOARDING: false,
  ENABLE_USER_REINVITATION: false,
  // By default, questionnaires do NOT have Insights graph
  INSIGHTS_GRAPH_WHITELIST: [],
  // By default, healthcards DO have a graph
  INSIGHTS_GRAPH_BLACKLIST: [CLINICARD_NAMES.PHOTO, CLINICARD_NAMES.GOALS],
  // Set to enable push notifications
  ONESIGNAL_APP_ID: '',
  ONESIGNAL_API_URL: 'https://onesignal.com/api/v1/notifications',
  SERVICE_DESK_EMAIL: 'helpdesk@synappz.nl',
  logo,
  logoPayoff,
  MANUAL_URL: {
    nl: 'https://clinicards-nl.refined.site',
    en: 'https://clinicards-en.refined.site',
  },
  DEFAULTCARDS: [],
  DEFAULTCAREPATH: undefined,
  MEDICAL_PROFILE_QUESTIONS: [],
  ENABLE_ANONYMITY: false,
  ENABLE_PATIENT_TRANSFER: false,
  ENABLE_LIST_CHILD_NAME: false,
  ENABLE_PATIENT_MFA: false,
  ENABLE_OPENID_SSO: false,
  SSO_PROVIDER: '',
  ENABLE_VPK: false,
  ENABLE_USAGE_ACTIVITY: false,
  SHOW_PHOTOS: true,
  ENABLE_CREATED_USER_DATE: true,
  ENABLE_AZURE_SSO: false,
  ENABLE_ANALYSE: false,
  ENABLE_SIGNALING: false,
  ENABLE_REFERENCE: false,
  CHOOSE_CAREPATH: true,
  ENABLE_BIRTH_INFO: false,
  VISUALISE_INACTIVE_USERS: false,
  ENABLE_ARTICLE_EXTERNALLINK: false,
  REMOVE_CUSTOMER_NUM_FROM_ONBOARDING: false,
  ARTICLE_AUTOMATIC_TRANSLATION: false,
  SSO_ORGANISATIONS: [],
};
