import logo from 'assets/images/ikh_logo.svg';
import logoPayoff from 'assets/images/ikh_logo_payoff.svg';
import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:3bb11125-d322-424d-b6f2-308812a7d578',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_7OqlSvHQr',
  aws_user_pools_web_client_id: '4osbnagq014d0t3d79r0dpglrf',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://abtn4tjnhjdb7penheqzfdzmrm.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint:
        'https://oopdhp13gf.execute-api.eu-west-1.amazonaws.com/ikherstel',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'clinicardstorage93322-ikherstel',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  Auth: {
    cookieStorage: {
      domain:
        process.env.NODE_ENV === 'production'
          ? 'portal.ikherstel.nl'
          : 'localhost',
      path: '/',
      expires: 1,
      secure: process.env.NODE_ENV === 'production',
    },
  },
};

const defaultCarepathCards = [];

export default {
  ...defaultConfigs,
  APP_TITLE: 'ikHerstel',
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: true,
  ENABLE_MEDICAL_PROFILE: true,
  ENABLE_MANAGEMENT: true,
  ENABLE_ORGANISATION_LEVEL: true,
  ENABLE_ORGANISATION_MULTIPLE: true,
  ENABLE_PERMISSIONS: true,
  ENABLE_CAREPATHS_ARTICLES: true,
  ENABLE_USER_REINVITATION: true,
  AWS,
  defaultCarepathCards,
  logo,
  logoPayoff,
};
