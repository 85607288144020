import { CLINICARD_NAMES } from 'constants/constants';
import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:c5feefec-7106-4f89-99ab-8e747b41d427',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_opVQqlRja',
  aws_user_pools_web_client_id: '6b69qhl07qfdnf2i263i2ui1aq',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://36bhw2jqobbvnkodt7kqum72ja.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint:
        'https://a74epevok6.execute-api.eu-west-1.amazonaws.com/original',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'clinicardstorage104128-original',
  aws_user_files_s3_bucket_region: 'eu-west-1',
};

const defaultCarepathCards = [
  'bmi',
  'prosthesis_questionnaire',
  'prepare_visit',
  'mood',
  'caregiver_strain_index',
  'vas_painscore',
  'steps',
  'photo',
  'exercises',
  'goals',
  'temperature',
  'blood_pressure',
  'heartrate',
  'saturation',
  'vas_painscore',
  'treatment_questionnaire',
  'information_questionnaire',
  'eating_questionnaire',
  'exercises_questionnaire',
  'breastfeeding',
];

export default {
  ...defaultConfigs,
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: true,
  ENABLE_MEDICAL_PROFILE: false,
  ENABLE_MANAGEMENT: true,
  ENABLE_CARETEAM_SHARED: false,
  INSIGHTS_GRAPH_WHITELIST: [CLINICARD_NAMES.CSI],
  AWS,
  defaultCarepathCards,
};
