import slingeland from './slingeland';
import umcg from './umcg';
import longembolie from './longembolie';
import handapp from './handapp';
import olvg from './olvg';
import sandbox from './sandbox';
import original from './original';
import petcard from './petcard';
import zwanger from './zwanger';
import neozorg from './neozorg';
import diakonessenhuis from './diakonessenhuis';
import ikherstel from './ikherstel';
import adhday from './adhday';
import winq from './winq';
import loss from './loss';
import voorzorg from './voorzorg';
import nazorg from './nazorg';
import zorgpunt from './zorgpunt';
import fcgp from './fcgp';
import ouderkind from './ouderkind';
import jaundice from './jaundice';
import vitality from './vitality';
import bkz from './bkz';
import mijnhand from './mijnhand';
import zorgpunttest from './zorgpunttest';
import pet from './pet';

const clients = {
  slingeland,
  umcg,
  longembolie,
  handapp,
  olvg,
  sandbox,
  original,
  petcard,
  zwanger,
  neozorg,
  diakonessenhuis,
  ikherstel,
  adhday,
  winq,
  loss,
  voorzorg,
  nazorg,
  zorgpunt,
  fcgp,
  ouderkind,
  jaundice,
  vitality,
  bkz,
  mijnhand,
  zorgpunttest,
  pet,
};

const clientConfig = clients[process.env.REACT_APP_CLINICARDS_CLIENT];
clientConfig.ENV = process.env.REACT_APP_CLINICARDS_CLIENT;

if (!clientConfig) {
  throw new Error(
    `Environment has not been configured with Clinicards client. Please set REACT_APP_CLINICARDS_CLIENT-variable with one of the following options: ${Object.keys(
      clients
    ).join(', ')}`
  );
}
export default clientConfig;
