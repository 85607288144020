/* eslint-disable import/prefer-default-export */
export const LOGIN = '/';
export const FORGOT = '/forgot';
export const CONFIRM = '/confirm';
export const ACTIVATED = '/activated';
export const LOGOUT = '/logout';
export const LOGINREAL = '/login';

export const PATIENT_LIST = '/patients';
export const PATIENT_ADD = '/patients/add';
export const PATIENT_ADD_CAREPATH = '/patients/addcarepath/:id';
export const PATIENT_ID = '/patients/:id';

export const SIGNALING_LIST = '/patients/signaling';
export const SIGNALING_PATIENT_ID = '/patients/signaling/:id?/:carepathId?';

export const CAREPATH_LIST = '/carepaths';
export const CAREPATH_ADD = '/carepaths/add';
export const CAREPATH_ID = '/carepaths/:id';

export const ARTICLE_LIST = '/articles';
export const ARTICLE_ITEM = '/articles/:id';

export const MANAGEMENT = '/management';
export const MANAGEMENT_USER = '/management/user';
export const MANAGEMENT_USER_REL = '/user';
export const MANAGERUSER_ADD = '/management/user/add';
export const MANAGERUSER_ID = '/management/user/:id';
export const MANAGERUSER_ID_REL = '/user/:id';

export const MANAGEMENT_USAGE = '/management/usageactivity';
export const MANAGEMENT_USAGE_REL = '/usageactivity';

export const MANAGEMENT_ORG = '/management/organisation';
export const MANAGEMENT_ORG_REL = '/organisation';
export const MANAGEMENT_ORG_ADD = '/management/organisation/add';
export const MANAGEMENT_ORG_ID = '/management/organisation/:id';
export const MANAGEMENT_ORG_ID_REL = '/organisation/:id';

export const MANAGEMENT_CAREGIVER = '/management/caregiver';
export const MANAGEMENT_CAREGIVER_REL = '/caregiver';
export const MANAGEMENT_CAREGIVER_ADD = '/management/caregiver/add';
export const MANAGEMENT_CAREGIVER_ID = '/management/caregiver/:id';
export const MANAGEMENT_CAREGIVER_ID_REL = '/caregiver/:id';

export const MANAGEMENT_CAREINSTITUTION = '/management/careinstitution';
export const MANAGEMENT_CAREINSTITUTION_REL = '/careinstitution';
export const MANAGEMENT_CAREINSTITUTION_ADD = '/management/careinstitution/add';
export const MANAGEMENT_CAREINSTITUTION_ID = '/management/careinstitution/:id';
export const MANAGEMENT_CAREINSTITUTION_ID_REL = '/careinstitution/:id';

export const MANAGEMENT_GROUP_PERMISSSION = '/management/permissions';
export const MANAGEMENT_GROUP_PERMISSSION_REL = '/permissions';
export const MANAGEMENT_GROUP_PERMISSSION_ADD = '/management/permissions/add';
export const MANAGEMENT_GROUP_PERMISSSION_ADD_REL = '/permissions/add';
export const MANAGEMENT_GROUP_PERMISSSION_ID = '/management/permissions/:id';
export const MANAGEMENT_GROUP_PERMISSSION_ID_REL = '/permissions/:id';
export const MANAGEMENT_GROUP_PERMISSSION_ID_UID =
  '/management/permissions/:id/:uid';

export const ABOUT = '/about';

export const MESSAGE_CENTER = '/messagecenter';
export const MESSAGE_CENTER_ITEM = '/messagecenter/:id';

export const TRANSFERIUM = '/transferium';
export const OUTGOING_TRANSFER = '/transferium/outgoingtransfers';
export const OUTGOING_TRANSFERS = '/outgoingtransfers';
export const INCOMING_TRANSFER = '/transferium/incomingtransfers';
export const INCOMING_TRANSFERS = '/incomingtransfers';

export const URL = (url, param, param1) => {
  const path = url.substring(0, url.indexOf(':'));
  if (param1) {
    return `${path}${param}/${param1}`;
  }
  return `${path}${param}`;
};

export const LOGIN_USING_SURF = '/loginsurf';

export const ANALYSE = '/analyse';
