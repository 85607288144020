import logo from 'assets/images/petcard_logo.svg';
import logoPayoff from 'assets/images/petcard_logo_payoff.png';
import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:16bcee01-deef-487d-909b-7b2109880dfe',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_6PjTO46Ci',
  aws_user_pools_web_client_id: '6hcvj6vs403rp4ensnko7bc3rv',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://lj6ftkxcync5dfaxh6vtl2dcm4.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint:
        'https://bjsy6hf2mj.execute-api.eu-west-1.amazonaws.com/petcards',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'clinicardstorage133710-petcards',
  aws_user_files_s3_bucket_region: 'eu-west-1',
};

const defaultCarepathCards = [];

export default {
  ...defaultConfigs,
  APP_TITLE: 'Petcards',
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: true,
  ENABLE_MEDICAL_PROFILE: false,
  ENABLE_MANAGEMENT: true,
  ENABLE_PERSONAL_MESSAGES: true,
  AWS,
  defaultCarepathCards,
  logo,
  logoPayoff,
};
