import logo from 'assets/images/logo_zorgpunt_test.png';
import logoPayoff from 'assets/images/zorgPunt_test_logo_payoff.svg';
import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_appsync_graphqlEndpoint:
    'https://vjvrwkecnzcwpi35suvsscvly4.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint:
        'https://dds9x8d500.execute-api.eu-west-1.amazonaws.com/zpunttest',
      region: 'eu-west-1',
    },
  ],
  aws_cognito_identity_pool_id:
    'eu-west-1:def88a11-4369-4738-b65a-12184846d8b1',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_sSSmZ9oIe',
  aws_user_pools_web_client_id: '1uagpqhfcrv9krgj1eu30ki5q4',
  oauth: {
    domain: 'zorgpunttest.auth.eu-west-1.amazoncognito.com',
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'https://www.zorgpunttest.clinicards.app/',
    redirectSignOut: 'https://zorgpunttest.clinicards.app/logout',
    responseType: 'code',
  },
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: '8',
    passwordPolicyCharacters: ['REQUIRES_NUMBERS'],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket: 'clinicardstorage92349-zpunttest',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  Auth: {
    cookieStorage: {
      domain:
        process.env.NODE_ENV === 'production'
          ? 'zorgpunttest.clinicards.app'
          : 'localhost',
      path: '/',
      expires: 1,
      secure: process.env.NODE_ENV === 'production',
    },
  },
};

const defaultCarepathCards = [];

export default {
  ...defaultConfigs,
  APP_TITLE: 'ZorgPunt Test',
  APP_CODE: 'CLIN-APP-0023',
  CONTENT_LANGUAGE: 'nl',
  CONTENT_LANGUAGES: ['nl'],
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: false,
  ENABLE_CARDS: true,
  ENABLE_MEDICAL_PROFILE: false,
  ENABLE_MANAGEMENT: true,
  ENABLE_CLINICARDS: true,
  ENABLE_ORGANISATION_LEVEL: true,
  ENABLE_ORGANISATION_DETAILS: true,
  ENABLE_INSIGHTS: false,
  ENABLE_ORGANISATION_MULTIPLE: true,
  ENABLE_PERMISSIONS: true,
  ENABLE_CHILD_PROFILE: true,
  ENABLE_PATIENT_TRANSFER: false,
  ENABLE_LIST_CHILD_NAME: true,
  AWS,
  logo,
  logoPayoff,
  defaultCarepathCards,
  DEFAULTCARDS: [
    '62369046-980d-4ea6-9e78-1b1b69068e08',
    '7c91f545-4075-40a1-8f27-1e393b06bca4',
    '54wsa12g-cea7-9923-bf8a-17d8cb552262',
    'de673886-5669-4e0d-b142-6fda146d2334',
    '54ds123sa-cea7-9923-12as-17d8cb552262',
  ],
  ENABLE_PATIENT_MFA: true,
  ENABLE_OPENID_SSO: false,
  ENABLE_AZURE_SSO: true,
  SSO_PROVIDER: 'AzureADZorgpuntTest',
  ENABLE_VPK: true,
  ENABLE_PERSONAL_MESSAGES: true,
  ENABLE_HIDE_PATIENT_BIRTHDATE: true,
  REMOVE_GENDER_FROM_ONBOARDING: true,
  ENABLE_BIRTH_INFO: true,
};
