import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:e78fe810-65a1-4686-b781-171431aa38ac',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_Qa8rRd26Y',
  aws_user_pools_web_client_id: '4pn9odg9jaos54e3bd67rstpp5',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: '8',
    passwordPolicyCharacters: ['REQUIRES_NUMBERS'],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_appsync_graphqlEndpoint:
    'https://rxdnxcb5nnf2ldpyhjcd2piaa4.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint: 'https://qmexq0fcik.execute-api.eu-west-1.amazonaws.com/lossb',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'clinicardstorage154808-lossb',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  aws_cognito_login_mechanisms: ['EMAIL'],
  Auth: {
    cookieStorage: {
      domain:
        process.env.NODE_ENV === 'production'
          ? 'loss.clinicards.app'
          : 'localhost',
      path: '/',
      expires: 1,
      secure: process.env.NODE_ENV === 'production',
    },
  },
};

const defaultCarepathCards = [];

export default {
  ...defaultConfigs,
  APP_TITLE: 'LOSS',
  ENABLE_ARTICLES: true,
  ENABLE_OPERATION_PROFILE: true,
  ENABLE_PERMISSIONS: true,
  ENABLE_MANAGEMENT: true,
  ENABLE_ORGANISATION_LEVEL: true,
  ENABLE_ORGANISATION_MULTIPLE: true,
  CONTENT_LANGUAGE: 'nl',
  SITE_LANGUAGES: ['nl', 'en', 'fr'],
  CONTENT_LANGUAGES: ['nl', 'en', 'fr'],
  AWS,
  defaultCarepathCards,
};
