import logo from 'assets/images/zwapp-app_logo.png';
import logoPayoff from 'assets/images/zwanger_payoff.png';
import favicon from 'assets/images/zwapp-flavicon.png';
import favicon16 from 'assets/images/zwapp-favicon-16x16.png';
import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:ed4d9842-e948-4707-be6f-3a17058506d5',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_g9g9O0YdV',
  aws_user_pools_web_client_id: '1h5v451ealc3ephkhlcs5c4gdb',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://2mpa2sqfqvf2jcg5gg2ict5rii.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint:
        'https://42t46reto2.execute-api.eu-west-1.amazonaws.com/zwanger',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'clinicardstorage100026-zwanger',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  Auth: {
    cookieStorage: {
      domain:
        process.env.NODE_ENV === 'production'
          ? 'zwanger.clinicards.app'
          : 'localhost',
      path: '/',
      expires: 1,
      secure: process.env.NODE_ENV === 'production',
    },
  },
};

const defaultCarepathCards = [
  'mood',
  'photo',
  'notes',
  'blood_pressure_uplow',
  'weight',
];

export default {
  ...defaultConfigs,
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: true,
  ENABLE_MEDICAL_PROFILE: false,
  ENABLE_MANAGEMENT: true,
  ENABLE_STICKY_CAREPATHITEM: true,
  ENABLE_ORGANISATION_LEVEL: true,
  ENABLE_ORGANISATION_DETAILS: true,
  AWS,
  logoPayoff,
  logo,
  favicon16,
  favicon,
  defaultCarepathCards,
};
